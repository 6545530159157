/* eslint no-negated-condition: 0, no-new-func: 0 */

'use strict';

if (typeof self !== 'undefined') {
  module.exports = self;
} else if (typeof window !== 'undefined') {
  module.exports = window;
} else {
  module.exports = Function('return this')();
}